import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { JitsuProvider } from '@jitsu/jitsu-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const jitsuConfig = {
  host: 'https://clz73jnct0000456qyn7m9obs.d.jitsu.com',
  writeKey: 'usjcmUipgDuZQtJTgvuGWrRfgJxmjyMP:OeyJTOTMMZlUaq4UXJFEK3Bq0AqnM7Vz',
};

root.render(
  <JitsuProvider options={jitsuConfig}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </JitsuProvider>
);
