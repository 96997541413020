import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useJitsu } from '@jitsu/jitsu-react';
import '../App.css'; // Certifique-se de importar o arquivo CSS

const RedirectPage = () => {
    const { shortLink } = useParams();
    const { analytics } = useJitsu();

    useEffect(() => {
        document.title = "Redirecionando...";

        const fetchAndRedirect = async () => {
            try {
                console.log(`Fetching URL for short link: ${shortLink}`);
                const response = await fetch(`https://x7b3-fyoq-iu1r.b2.xano.io/api:Tys7AsZO/redirect?short_link=${shortLink}`);

                if (!response.ok) {
                    console.error('Erro na resposta da API:', response.status, response.statusText);
                    return;
                }

                const data = await response.json();
                console.log('Dados recebidos da API:', data);

                if (typeof data === 'string' || data.url) {
                    const finalUrl = typeof data === 'string' ? data : data.url;

                    analytics.page().then(() => {
                        window.location.href = finalUrl;
                    }).catch(error => {
                        console.error('Erro ao capturar evento de analytics:', error);
                        window.location.href = finalUrl; // Redirecionar mesmo em caso de erro no analytics
                    });
                } else {
                    console.error('URL não encontrada no corpo da resposta');
                }
            } catch (error) {
                console.error('Erro ao obter a URL:', error);
            }
        };

        fetchAndRedirect();
    }, [shortLink, analytics]);

    return (
        <div className="loader-wrapper">
            <div className="loader"></div>
            <span>Estamos recuperando os dados do seu carrinho...</span>
        </div>
    );
};

export default RedirectPage;
