import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import RedirectPage from './components/RedirectPage';

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/') {
      window.location.href = 'https://fleezy.com.br';
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/:shortLink" element={<RedirectPage />} />
    </Routes>
  );
};

export default App;
